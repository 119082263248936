.search-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .search-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="text"] {
    width: 400px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px 20px 20px 20px;
    margin: 5px;
    font-size: 16px;
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #357ae8;
  }
  